.App {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 0;
  text-align: left;
}

.App-logo {
  width: 520px;
  pointer-events: none;
}

body {
  background-color: #282c34;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header, .App-main {
  background-color: #282c34;
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: 26px;
  font-weight: 200;
  color: white;
}

.App-main p {
  padding: 0 82px;
}

.App-link {
  color: #61dafb;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
