body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

main {
  flex-grow: 1;
  padding: 20px;
}

footer {
  /* background: #222; */
  color: #fff;
  text-align: left;
  padding: 10px 100px 50px;
}

footer a {
  color: #61dafb;
}